import React, { Component } from "react"
import Button from "../button"

import axios from "axios"

import "./styles.scss"

class ContactForm extends Component {
    constructor(props) {
        super(props)
        this.blok = props.blok
        this.state = {
            email: "",
            message: "",
            dataprivacy: false
        }

        this.handleMessageChange = this.handleMessageChange.bind(this)
        this.handleEmailChange = this.handleEmailChange.bind(this)
        this.handlePrivacyChange = this.handlePrivacyChange.bind(this)
        this.send = this.send.bind(this)
    }

    handleMessageChange(event) {
        this.setState({message: event.target.value})
    }

    handleEmailChange(event) {
        this.setState({email: event.target.value})
    }

    handlePrivacyChange(event) {
        if (event.target.checked) {
            this.setState({dataprivacy: true})
        } else {
            this.setState({dataprivacy: false})
        }
    }

    async send(event) {
        event.preventDefault()
        if (this.state.email !== "" && this.state.message !== "" && this.state.dataprivacy) {
            try {
                const response = await axios.post("https://api.city-toolbox.conducolabs.dev/contact", {
                    email: this.state.email,
                    message: this.state.message
                })
                window.location.replace(response.data.link.replace("/de", ""))
            } catch(error) {
                alert("Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.")
            }
        } else {
            alert("Bitte geben Sie eine gültige E-Mail Adresse und Nachricht ein. Ebenso müssen Sie die Datenschutz-Bedingungen akzeptieren.")
        }
    }

    render() {
        return (
            <div className="full-page">
                <div className="form-box">
                    <h2>Sie möchten weitere Informationen zu einem Thema erhalten? Kein Problem! Schreiben Sie uns gerne eine Nachricht und wir melden uns bei Ihnen.</h2>
                    <div className="form-row">
                        <label for="email">E-Mail Adresse<span className="mandatory">*</span></label>
                        <input type="email" name="email" id="email" value={this.state.email} onChange={this.handleEmailChange} placeholder="erika.mustermann@adresse.de" />
                    </div>
                    <div className="form-row">
                        <label for="message">Ihre Nachricht<span className="mandatory">*</span></label>
                        <textarea onChange={this.handleMessageChange} placeholder="Erzählen Sie uns kurz, wie wir Sie unterstützen können…">
                            {this.state.message}
                        </textarea>
                    </div>
                    <div className="form-row dataprivacy">
                        <input type="checkbox" value="1" name="dataprivacy" id="dataprivacy" onChange={this.handlePrivacyChange} />
                        <label for="dataprivacy">Ich stimme der Datenschutz-Erklärung zu und gestatte Ihnen, mich im Rahmen meiner Anfrage zu kontaktieren.</label>
                    </div>
                    <div className="actions">
                        <Button type="primary" to="#" action={this.send} label="Nachricht abschicken" />
                    </div>
                </div>
            </div>
        )
    }
}  
  
export default ContactForm